import React from 'react';

const Navbar = () => {
  return (
    <nav style={styles.nav}>
      <div style={styles.logo}>
        <img src="rugcheck-logo.png" alt="RugCheck Logo" style={styles.logoImg} />
        <span>RugCheck</span>
      </div>
      <div style={styles.links}>
        <a href="#" style={styles.link}>HOME</a>
        <a href="#" style={styles.link}>TOKENS</a>
        <a href="#" style={styles.link}>VERIFY</a>
        <a href="#" style={styles.link}>INTEGRATIONS</a>
        <a href="#" style={styles.link}>DEX</a>
      </div>
      <button style={styles.loginBtn}>Login</button>
    </nav>
  );
};

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#2a2a2a',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImg: {
    width: '30px',
    height: '30px',
    marginRight: '10px',
  },
  links: {
    display: 'flex',
    gap: '20px',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '14px',
  },
  loginBtn: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '5px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Navbar;