import React from 'react';

const MainSection = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        Claim Your Rug <br /> solana Fees
      </h1>
      <input
        type="text"
        placeholder="Token Address"
        style={styles.input}
      />
      <button style={styles.checkBtn}>Check</button>
    </div>
  );
};

const styles = {
  container: {
    margin: '50px 0',
  },
  heading: {
    fontSize: '48px',
    lineHeight: '1.2',
  },
  input: {
    width: '300px',
    padding: '10px',
    margin: '20px 0',
    backgroundColor: '#333',
    border: '1px solid #555',
    color: 'white',
    fontSize: '16px',
  },
  checkBtn: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '2px solid white',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

export default MainSection;