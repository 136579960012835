import React from 'react';

const ClaimSection = () => {
  return (
    <div style={styles.container}>
      <button style={styles.claimBtn}>
        🛡️ TOKEN DOMAINS ARE NOW AVAILABLE - Claim Now!
      </button>
    </div>
  );
};

const styles = {
  container: {
    margin: '20px 0',
  },
  claimBtn: {
    backgroundColor: '#2a5c3a',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
  },
};

export default ClaimSection;