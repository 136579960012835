import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <a href="#" style={styles.link}>RugCheck</a>
      <a href="#" style={styles.link}>ABOUT</a>
      <a href="#" style={styles.link}>TOKENS</a>
      <a href="#" style={styles.link}>LAUNCH TOOLS</a>
      <a href="#" style={styles.link}>API</a>
      <a href="#" style={styles.link}>INTEGRATIONS</a>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#2a2a2a',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '14px',
  },
};

export default Footer;